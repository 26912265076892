* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	outline: none;
}

html,
body {
	width: 100%;
	height: 100vh;
	margin: 0;
	padding: 0;
	overflow: hidden;
	background-color: #fff;
}

body {
	overscroll-behavior-y: none;
	font-family: 'League Spartan', sans-serif;
	cursor: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiMyMjIiIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLW1vdXNlLXBvaW50ZXIiPjxwYXRoIGQ9Ik0zIDNsNy4wNyAxNi45NyAyLjUxLTcuMzkgNy4zOS0yLjUxTDMgM3oiPjwvcGF0aD48cGF0aCBkPSJNMTMgMTNsNiA2Ij48L3BhdGg+PC9zdmc+'),
		auto;
}

a {
	text-decoration: none;
	color: #000;
	transition: 0.3s;

	&:hover {
		color: #b66089 !important;
	}
}

.container {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.content {
	height: 100vh;
	opacity: 0;
	animation: fadeIn 0.5s;
	animation-fill-mode: forwards;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
