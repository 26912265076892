.text {
	width: 100vw;
	font-size: 16px;
	max-width: 670px;
	font-size: 24px;

	@media (max-width: 1920px) {
		max-width: 470px;
		font-size: 16px;
	}

	@media (max-width: 767px) {
		width: 85vw;
	}
}

.content {
	padding: 20px;
	width: 100%;
	height: 100%;
	overflow: hidden;
	transition: 0.5s;

	@media (max-width: 767px) {
		padding: 30px;
		transform: translateY(90px);
	}

	@media (max-width: 500px) {
		padding: 0;
		transform: translateY(30px);
	}

	@for $i from 1 through 4 {
		.field:nth-of-type(#{$i}) {
			animation-delay: #{$i * 0.1}s;
		}
	}

	.field {
		font-family: Antonio, sans-serif;
		opacity: 0;
		display: flex;
		animation: fadeInField 1.5s;
		animation-fill-mode: forwards;
		animation-timing-function: cubic-bezier(0.9, 0, 0.05, 1);

		@media (max-width: 767px) {
			animation: fadeInFieldMobile 1.5s;
			animation-fill-mode: forwards;
			animation-timing-function: cubic-bezier(0.9, 0, 0.05, 1);
		}

		.label {
			padding-top: 10px;
			width: 100px;
			font-weight: 700;
			text-transform: uppercase;
			font-size: 20px;
			margin-bottom: 5px;
			margin-right: 15px;

			@media (max-width: 1920px) {
				font-size: 14px;
			}
		}

		input,
		textarea {
			pointer-events: all;
			font-size: 20px;
			font-family: 'Inter';
			margin-bottom: 10px;
			width: 100%;
			resize: none;
			border-radius: 3px;
			padding: 15px;
			border: 1px solid rgba(0, 0, 0, 0.15);
			transition: 0.3s;
			outline: 1px solid rgba(0, 0, 0, 0);

			&::placeholder {
				color: #ccc;
			}

			&:focus {
				outline: 1px solid rgba(0, 0, 0, 0.2);
			}

			@media (max-width: 1920px) {
				font-size: 14px;
			}
		}

		textarea {
			height: 140px;

			@media (max-width: 900px) {
				height: 60px;
			}
		}
	}

	@keyframes fadeInField {
		0% {
			opacity: 0;
			margin-top: 140px;
		}
		100% {
			opacity: 1;
			margin-top: 0;
		}
	}

	@keyframes fadeInFieldMobile {
		0% {
			opacity: 0;
			transform: translateY(50px);
			margin-top: 0;
		}
		100% {
			opacity: 1;
			// transform: translateY(-30px);
			margin-top: 0;
		}
	}

	.footer {
		opacity: 0;
		animation: fadeInField 1.5s;
		animation-delay: 0.5s;
		animation-fill-mode: forwards;
		animation-timing-function: cubic-bezier(0.9, 0, 0.05, 1);
		display: flex;
		margin-top: 20px;

		.error {
			margin-left: 100px;
			font-size: 11px;
			display: flex;
			align-items: center;
			flex: 1;

			img {
				margin-right: 8px;
			}
		}

		@media (max-width: 767px) {
			animation: fadeInFieldMobile 1.5s;
			animation-fill-mode: forwards;
			animation-timing-function: cubic-bezier(0.9, 0, 0.05, 1);
		}
	}

	&.fieldsOut {
		transition-delay: 0.6s;
		opacity: 0;
	}
}

.submitting {
	height: 135px;
	width: 300px;
}

.thankYou {
	letter-spacing: -0.2px;
	margin-top: 100px;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	text-align: center;

	button {
		margin-top: 20px;
	}
}

.submitButton {
	pointer-events: all;
	cursor: pointer;
	border-radius: 3px;
	text-transform: uppercase;
	font-family: Antonio;
	font-weight: 600;
	letter-spacing: 2px;
	color: #fff;
	width: 100px;
	border: none;
	padding: 15px;
	background-color: #222;
	transition: 0.3s;

	&:hover {
		background-color: #555;
	}
}
