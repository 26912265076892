.container {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	padding: 40px;
	user-select: none;
	transition: 0.5s;
	-webkit-filter: invert(0%);
	filter: invert(0%);
	transition-delay: 1s;

	&.light {
		transition-delay: 1s;
		-webkit-filter: invert(100%);
		filter: invert(100%);
	}

	@media (max-width: 500px) {
		padding: 30px;
	}

	.menu {
		transition: 0.5;
		position: fixed;
		pointer-events: none;
		top: 30px;
		left: 0;
		width: 100vw;
		z-index: 10;
		display: flex;
		justify-content: center;
		user-select: none;

		.inner {
			pointer-events: all;
			display: flex;

			.menuItem {
				text-align: center;
				margin: 5px;
				text-transform: uppercase;
				font-size: 30px;
				font-family: Antonio;
				font-weight: 600;
				padding: 5px 0 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				width: 130px;
				position: relative;
				transition: 0.25s;
				cursor: pointer;

				.underlay {
					width: 100%;
					transition: 0.25s;
					transition-timing-function: ease-out;
					position: absolute;
					background-color: #222;
					height: 2px;
					border-radius: 2px;
					bottom: 0;
				}

				.selectedUnderlay {
					top: 0px;
					background-color: rgba(0, 0, 0, 0.1);
					width: 100%;
					transition: 0.5s;
					position: absolute;
					height: 0;
				}

				.label {
					display: inline-block;
					position: relative;
					z-index: 1;
				}

				&:hover {
					color: #a7efff;

					.underlay {
						height: 100%;
					}
				}
			}

			.menuItemActive {
				@media screen and (min-width: 1100px) {
					pointer-events: none;
					color: #222 !important;
					cursor: unset;

					.underlay {
						transition: 0.5s;
						opacity: 0;
					}

					.selectedUnderlay {
						height: 100%;
					}
				}
			}
		}
	}

	.header {
		.title {
			font-family: Antonio, sans-serif;
			font-size: 62px;
			font-weight: 600;
			line-height: 50px;
			color: #222222;
			letter-spacing: -0.1px;
			margin: 0;
			opacity: 1;

			@media (max-width: 1920px) {
				font-size: 42px;
				line-height: 34px;
			}
		}

		.subHeader {
			margin-top: 20px;
			font-size: 20px;
			color: #222222;

			h2 {
				font-weight: 700;
				margin: 0;
				font-size: 20px;
				display: block;
				margin-bottom: 10px;
			}

			b {
				display: block;
				font-weight: 800;
				font-size: 20px;
			}

			@media (max-width: 1920px) {
				font-size: 12px;

				h2 {
					margin-bottom: 0;
					font-size: 12px;
					line-height: 18px;
				}

				b {
					font-size: 12px;
					line-height: 18px;
				}
			}

			@media (max-width: 767px) {
				margin-top: 15px;
			}
		}
	}

	.dragCursor {
		position: absolute;
		right: -60px;
		bottom: 250px;
		transform: rotate3d(0, 0, 1, 90deg) translate3d(100%, 10px, 0);
		font-size: 12;
		font-weight: 700;
		line-height: 100%;
		text-align: right;
		color: #222222;
		white-space: nowrap;

		img {
			transform: rotate(-90deg) translateX(-5px);
			width: 20px;
		}

		span {
			margin: 0 20px;
		}
	}

	.handIcon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		font-weight: 700;
		font-size: 14px;
		transition: 0.5s;

		img {
			margin: 0 auto 10px;
			width: 50px;
			display: block;
		}
	}

	.fadeOut {
		opacity: 0 !important;
	}

	.social {
		position: absolute;
		top: 40px;
		right: 40px;
		white-space: nowrap;
		flex: 1 1 0%;
		font-size: 20px;
		text-align: right;
		line-height: 1.5em;
		color: #222222;

		@media (max-width: 1920px) {
			font-size: 12px;
		}

		.emailLink {
			pointer-events: all;
			display: inline-block;
		}

		.buttons {
			margin-right: -15px;
			transition: 1s;

			.button {
				position: relative;
				border-radius: 50%;
				margin: 10px 5px 0 -10px;
				padding: 10px;
				display: inline-block;
				transition: 0.3s;
				pointer-events: all;

				img {
					height: 30px;
					vertical-align: middle;
					cursor: pointer;
					transition: 0.3s;
					z-index: 1;
					position: relative;

					@media (max-width: 1920px) {
						height: 20px;
					}
				}

				.underlay {
					top: 10px;
					left: 0;
					position: absolute;
					border-radius: 50%;
					background-color: rgba(0, 0, 0, 0.2);
					opacity: 0;
					transition: 0.3s;
					width: 100%;
					height: 100%;
				}

				&:hover {
					img {
						filter: brightness(0) invert(1);
					}

					.underlay {
						opacity: 1;
						top: 0;
					}
				}
			}
		}
	}

	.viewCode {
		position: absolute;
		bottom: 20px;
		width: 100%;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 10px;
		pointer-events: all;

		a {
			text-transform: uppercase;
			margin-top: 5px;
			display: inline-flex;
			align-items: center;
			font-weight: 700;
		}

		img {
			height: 10px;
			margin-right: 5px;
		}
	}

	.showDesktop {
		display: inline-block;
	}

	.showMobile {
		display: none;
	}

	.handIcon {
		display: none;
	}

	@media screen and (max-width: 1920px) {
		.menu {
			left: 60px;

			.inner {
				.menuItem {
					width: 72px;
					font-size: 20px;
					width: 92px;
				}
			}
		}
	}

	@media screen and (max-width: 1024px) {
		.menu {
			left: 60px;

			.inner {
				.menuItem {
					width: 72px;
				}
			}
		}
	}

	@media screen and (max-width: 912px) {
		.handIcon {
			display: block;
			opacity: 0.8;
		}

		.showDesktop {
			display: none;
		}

		.dragCursor {
			opacity: 0;
		}

		.showMobile {
			display: inline-block;
		}

		.full {
			visibility: hidden;
		}

		.social {
			bottom: 30px;
			top: unset;
			right: unset;
			text-align: left;
		}

		.viewCode {
			display: none;
		}

		.menu {
			left: 35px;
			width: calc(100vw - 70px);

			.inner {
				width: 100%;
				display: flex;

				.menuItem {
					font-size: 16px;
					// width: 72px
					width: unset;
					flex: 1;
				}
			}
		}

		.header {
			margin-top: 70px;
		}
	}

	@media (max-width: 500px) {
		.menu {
			left: 20px;
			width: calc(100vw - 40px);

			.inner {
				width: 100%;
				display: flex;

				.menuItem {
					font-size: 16px;
					// width: 72px
					width: unset;
					flex: 1;
				}
			}
		}

		.header {
			margin-top: 60px;

			.title {
				font-size: 36px;
			}

			.subHeader {
				margin-top: 10px;
			}
		}
	}
}
