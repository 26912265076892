$slantHeight: 250px;

.container {
	background: linear-gradient(180deg, #a3dfed 0%, #f6f6f6 60%);
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	user-select: none;
	transition: 1s;
	overflow: hidden;

	.texture {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		opacity: 0.25;
		mix-blend-mode: overlay;
		background-size: cover;
		background-position: center;
	}

	.mouseOverlay {
		display: none;
		width: 1200px;
		height: 1200px;
		border-radius: 50%;
		position: absolute;
		transform: translate(-50%, -50%);
		opacity: 1;
		mix-blend-mode: multiply;
		background: radial-gradient(
			circle at center,
			#fca4b8 0 20%,
			rgba(255, 180, 196, 0) 50%
		);
		color: #373737;
		transition: 0.5s opacity;
		pointer-events: none;

		@media (max-width: 920px) {
			opacity: 1;
			display: block;
			transition: 0.5s;
		}
	}

	.splatter {
		opacity: 0.4;
		width: 100vw;
		height: 100vw;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		mix-blend-mode: overlay;
	}

	@mixin text {
		position: absolute;
		font-weight: 800;
		color: #000000;
		// animation: fadeIn 2s;
		// animation-fill-mode: forwards;
	}

	.text {
		// mix-blend-mode: overlay;
		transition: 1s;

		// .textMobile {
		// 	@include text;
		// 	display: none;
		// 	top: 39vh;
		// 	color: #146b83;
		// 	opacity: 0.2;

		// 	@media (max-width: 767px) {
		// 		display: block;
		// 	}

		// 	.largeText {
		// 		line-height: 26vw;
		// 		letter-spacing: -0.83vw;
		// 		font-size: 41.5vw;
		// 	}

		// 	.largeText2 {
		// 		line-height: 34vw;
		// 		letter-spacing: -0.83vw;
		// 		font-size: 44.5vw;
		// 	}

		// 	.smallText1 {
		// 		text-align: center;
		// 		margin-top: -10vw;
		// 		font-size: 10.5vw;
		// 		letter-spacing: -0.29vw;
		// 		line-height: 4.5vh;
		// 	}
		// }

		.textDesktop {
			@include text;
			mix-blend-mode: overlay;
			top: 35vh;
			white-space: nowrap;
			line-height: 7vw;

			@media (max-width: 767px) {
				display: none;
			}

			&.muteText {
				transition: opacity 1.5s;
				opacity: 0.1;
			}

			&.strongText {
				transition: opacity 1.5s 1s;
				opacity: 1;
			}

			.largeText {
				letter-spacing: -0.83vw;
				font-size: 10.5vw;
				line-height: 9vw;
				margin-left: -7vw;
			}

			.smallText {
				margin-left: 1.2vw;
				font-size: 5.5vw;
				letter-spacing: -0.29vw;
				line-height: 4.5vw;
			}

			.smallestText {
				font-size: 3.05vw;
				margin-left: -4vw;
				line-height: 2.5vw;
				letter-spacing: -0.19vw;
			}
		}
	}
}

.overlay {
	position: fixed;
	width: 100vw;
	height: 100vh;
	opacity: 0.5;
	mix-blend-mode: multiply;
	pointer-events: none;

	.overlayInner {
		pointer-events: none;
		position: absolute;
		width: 100%;
		animation-duration: 2s;
		animation-fill-mode: forwards;
		animation-delay: 0;
		animation-timing-function: cubic-bezier(0.9, 0, 0.05, 1);

		.top,
		.bottom {
			position: absolute;
			width: 100%;
			height: $slantHeight;
		}

		.top {
			top: -$slantHeight + 1;
		}

		.bottom {
			bottom: -$slantHeight;
		}

		&.animationIn {
			bottom: -$slantHeight;
			animation-name: transitionIn;
			animation-duration: 0.7;
			z-index: 1;
		}

		&.animationOut {
			top: 0;
			animation-duration: 0.7;
			animation-name: transitionOut;
		}

		@keyframes transitionIn {
			0% {
				height: 0;
			}
			100% {
				height: calc(100% + #{$slantHeight});
			}
		}

		@keyframes transitionOut {
			0% {
				height: calc(100% + #{$slantHeight});
			}
			100% {
				height: 0;
			}
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
