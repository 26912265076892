.container {
	position: relative;

	.text {
		padding-right: 15px;
		overflow: hidden;
		transition: 1.5s cubic-bezier(0.9, 0, 0.05, 1);
		left: 0;
		position: absolute;
		opacity: 0;

		&.open {
			opacity: 1;
			bottom: 0;
		}

		&.hide {
			// @media (max-width: 500px) {
			// 	display: none;
			// }

			@media (max-width: 376px) {
				display: none;
			}
		}
	}
}
