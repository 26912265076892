.container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 9px;
	color: #000;

	.numbers {
		padding-right: 30px;
		color: #fff;
		font-size: 260px;
		letter-spacing: -30px;
		font-weight: 100;
		font-weight: 800;
		opacity: 0.5;
		line-height: 260px;

		span {
			font-size: 60px;
		}

		@media (max-width: 500px) {
			font-size: 120px;
			line-height: 120px;
			letter-spacing: -10px;
		}
	}

	.spinnerContainer {
		.spinner {
			display: block;
			margin: 10px auto 10px;
			width: 45px;
			height: 35px;
			position: relative;
			transition: 0.5s;

			.spinnerBig,
			.spinnerSmall {
				position: absolute;
				animation: spin 1s;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
			}

			.spinnerSmall {
				top: 10px;
				left: 10px;
				width: 20px;
				height: 20px;
				animation-direction: reverse;
			}
		}
	}

	.progress {
		transition: 0.5s;
		text-align: center;

		.bar {
			width: 50px;
			height: 2px;
			background-color: rgba(0, 0, 0, 0.2);
			margin-bottom: 20px;

			.barInner {
				transition: 0.2s;
				height: 2px;
				background-color: #000;
			}
		}

		.assetsLoaded {
			font-family: Antonio;
			margin-top: 2px;
		}
	}

	.loaded {
		.progress {
			transform: translateY(30px);
			opacity: 0;
		}

		.spinner {
			transform: scale(3.5);
			margin-bottom: 50px;
			opacity: 0;
		}
	}

	.placeholder {
		opacity: 0;
		font-size: 0;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
