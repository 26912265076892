.info {
	white-space: nowrap;
	display: flex;
	gap: 10px;

	@media (max-width: 767px) {
		display: none;
	}

	.button {
		opacity: 0.7;
		cursor: pointer;
		color: #fff !important;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 30px;
		padding: 20px 30px;
		background-color: #000;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: -0.5px;
		font-size: 20px;
		font-family: Antonio, sans-serif;
		transition: 0.3s;

		img {
			height: 25px;
			margin-right: 10px;
			-webkit-filter: invert(100%); /* Safari/Chrome */
			filter: invert(100%);
			transition: 0.3s;
		}

		&:hover {
			background-color: #fff;
			color: #000 !important;

			img {
				-webkit-filter: invert(0%); /* Safari/Chrome */
				filter: invert(0%);
			}
		}

		span {
			display: inline-block;
			margin-top: -4px;
		}

		@media (max-width: 1300px) {
			padding: 15px 20px;
			font-size: 16px;

			img {
				height: 15px;
			}
		}
	}
}

.content {
	flex: 1;

	.aboutContainer {
		display: flex;
		gap: 20px;

		.portraitContainer {
			height: 415px;
			width: 400px;
			overflow: hidden;

			.portrait {
				border-radius: 5px;
				width: 100%;
				height: 100%;
				background-position: center;
				background-size: cover;
			}

			@media (max-width: 1920px) {
				height: 215px;
				width: 200px;
			}
		}

		.about {
			color: #ffffffc7;
			flex: 1;
			font-size: 24px;
			line-height: 32px;

			@media (max-width: 1920px) {
				font-size: 14px;
				line-height: 22px;
			}

			@media (max-width: 767px) {
				width: 100%;
			}
		}

		@media (max-width: 1200px) {
			.portraitContainer {
				display: none;
			}
		}

		@media (max-width: 900px) {
			display: block;

			.portraitContainer {
				margin-bottom: 20px;
			}
		}
	}

	.tech {
		color: #fff;
		margin-top: 40px;

		@media (max-width: 1400px) {
			display: none;
		}

		.row {
			display: flex;
			margin-bottom: 10px;
			font-size: 11px;

			.type {
				width: 80px;
				text-transform: uppercase;
			}

			.skills {
				flex: 1;

				@for $i from 1 through 15 {
					.skill:nth-of-type(#{$i}) {
						animation-delay: #{1 + $i * 0.05}s;
					}
				}

				.skill {
					opacity: 0;
					animation-name: fadeInSkill;
					animation-duration: 0.4s;
					animation-fill-mode: forwards;
					border-radius: 2px;
					padding: 2px 4px;
					color: #fff;
					background-color: rgba(0, 0, 0, 0.3);
					margin: 0 5px 3px 0;
					display: inline-block;
				}
			}
		}
	}
}

@keyframes fadeInSkill {
	0% {
		opacity: 0;
		transform: translateY(40px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
