.container {
	position: fixed;
	cursor: default;
	animation: fadeInBG 2s cubic-bezier(0.9, 0, 0.05, 1);
	animation-fill-mode: forwards;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	z-index: 10;
	transition: 0.5s;

	.x {
		position: fixed;
		cursor: pointer;
		user-select: none;
		top: 20px;
		right: 20px;
		transition: 0.3s;

		&:hover {
			transform: scale(1.2) rotate(90deg);
		}
	}

	.modal {
		background-color: rgba(250, 250, 250, 1);
		border-radius: 5px;
		opacity: 0;
		animation: fadeInModal 2s cubic-bezier(0.9, 0, 0.05, 1);
		animation-fill-mode: forwards;
		transition: 0.3s;
		height: 85vh;
		box-shadow: 0 20px 50px 50px rgba(0, 0, 0, 0.1);
		display: flex;
		position: relative;
		overflow: hidden;

		.overlay {
			z-index: 10;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.8);
			opacity: 1;
			transition: 0.5s;
			display: flex;
			align-items: center;
			justify-content: center;

			.prompt {
				text-align: center;
				color: #fff;
				transition: 0.5s;

				img {
					display: block;
					width: 50px;
					margin: 10px auto 0;
					transform: rotate(90deg);
					filter: invert(100%);
				}
			}

			&.hideOverlay {
				opacity: 0;
				pointer-events: none;

				.prompt {
					transform: translateY(20px);
				}
			}
		}

		.projects {
			animation: fadeInProjects 1s;
			animation-fill-mode: forwards;
			scroll-behavior: smooth;
			width: 100%;
			flex: 1;
			position: relative;
			overflow: auto;

			.nextProject {
				display: flex;
				justify-content: center;
				align-items: center;
				max-width: 50%;
			}

			.project {
				padding: 100px 0;
				position: relative;
				border-top: 1px solid rgba(0, 0, 0, 0.1);

				&:first-of-type {
					padding-top: 0;
				}

				&.alt {
					background-color: #f1f1f1;
				}

				.info {
					padding: 20px 40px 0 20px;
					font-size: 12px;
					display: inline-block;
					width: 300px;
					position: -webkit-sticky;
					position: sticky;
					top: 0;

					.logo {
						font-family: Antonio;
						width: 100%;
						text-align: left !important;
						margin-bottom: 30px;
						font-size: 30px;
						font-weight: 800;
						letter-spacing: -1px;

						img {
							width: 100%;
							height: 50px;
						}
					}

					.field {
						margin-top: 20px;
						margin-bottom: 10px;

						b {
							color: rgba(0, 0, 0, 0.8);
							margin-bottom: 5px;
							letter-spacing: 1px;
							text-transform: uppercase;
							font-size: 16px;
							font-weight: 400;
							letter-spacing: -1.5px;
							display: block;
						}
					}

					.skill {
						display: inline-block;
						padding: 3px 5px;
						border-radius: 3px;
						background-color: #eee;
						margin-right: 3px;
						margin-bottom: 3px;
						border: 1px solid rgba(0, 0, 0, 0.05);
					}
				}

				.media {
					padding: 0 20px;
					display: inline-block;
					width: calc(100% - 300px);
					vertical-align: top;

					.image {
						width: 100%;
						box-shadow: 0 0 60px rgba(0, 0, 0, 0.09),
							0 0 10px rgba(0, 0, 0, 0.05);
						margin-top: 20px;
					}

					.video {
						width: 100%;
						box-shadow: 0 0 60px rgba(0, 0, 0, 0.09),
							0 0 10px rgba(0, 0, 0, 0.05);
						margin-top: 20px;
					}
				}
			}

			.anchor {
				height: 20px;
			}
		}

		@media (max-width: 1023px) {
			.projects {
				// padding: 0;

				.project {
					.logo {
						font-size: 3vw;
					}
				}
			}
		}

		@media (max-width: 767px) {
			flex-direction: column;

			.projects {
				.project {
					background-color: #fff !important;
					padding-top: 0;

					.info {
						height: auto;
						width: 100%;
						padding: 15px 20px 10px !important;
						background-color: #fff;
						box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
						// position: relative;

						.logo {
							margin-bottom: 0;
							font-size: 30px;
						}

						.field {
							b {
								margin-bottom: 3px;
							}
						}

						.controls {
							left: 20px;

							.nextProject {
								right: 40px;
							}
						}

						.field:nth-last-of-type(-n + 2) {
							display: none;
						}
					}

					.media {
						width: 100%;
					}
				}
			}
		}
	}

	&.closingModal {
		opacity: 0 !important;
	}

	h1 {
		font-size: 0;
		opacity: 0;
	}
}

@keyframes fadeInBG {
	0% {
		background-color: rgba(0, 0, 0, 0);
	}

	100% {
		background-color: rgba(0, 0, 0, 0.6);
	}
}

@keyframes fadeInModal {
	0% {
		opacity: 0;
		width: 0;
	}

	100% {
		opacity: 1;
		width: 95vw;
	}
}

@keyframes fadeInProjects {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
