.container {
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100.2vw;
	height: 100vh;
	z-index: 10;

	h1 {
		font-size: 0;
		opacity: 0;
		pointer-events: none;
	}

	.title {
		pointer-events: all;
		mix-blend-mode: overlay;
		position: absolute;
		z-index: 10;
		top: 30vh;
		left: 30px;

		.noWrap {
			white-space: nowrap;
		}

		.text {
			opacity: 0.75;
			letter-spacing: -1vw;
			font-size: 10vw;
			font-weight: 800;
			color: #fff;
			line-height: 8vw;
			margin-bottom: 40px;
		}

		@media (max-width: 920px) {
			top: 280px;

			.text {
				font-size: 10vw;
				line-height: 9vw;
			}
		}

		@media (max-width: 767px) {
			top: 220px;

			.text {
				font-size: 18vw;
				line-height: 16vw;
			}
			.info {
				transform: translateY(-20px);
				width: calc(100% - 30px);
			}
		}

		@media (max-width: 500px) {
			top: 190px;

			.text {
				font-size: 13vw;
				line-height: 13vw;
			}
			.info {
				transform: translateY(-20px);
				width: calc(100% - 30px);
			}
		}
	}

	.body {
		left: 45vw;
		top: 30vh;
		width: 42%;
		position: absolute;

		@media (max-width: 920px) {
			top: 600px;
			left: 0px;
			width: 90%;
			padding: 0 30px;
		}

		@media (max-width: 767px) {
			top: unset;
			bottom: 170px;
			left: 0px;
			width: 100%;
		}

		@media (max-width: 500px) {
			bottom: 200px;
			width: calc(100vw);
		}

		@media (max-width: 376px) {
			bottom: 150px;
			width: calc(100vw);
		}
	}
}
